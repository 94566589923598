import React, { FC } from 'react';
import { Link } from 'gatsby';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import BreadCrumbs from 'components/common/BreadCrumbs';
import CompatibilityList from 'components/CompatibilityList/CompatibilityList';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IProductsBanner } from './models';
import './ProductsBanner.scss';

const ProductsBanner: FC<IProductsBanner> = ({
  title,
  bannerDescription,
  bannerImage,
  bannerImageMobile,
  bannerLink,
  bannerLinkAriaLabel,
  breadCrumbs,
  tags,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div className="products-banner">
      <div className="products-banner__content">
        {breadCrumbs ? <BreadCrumbs data={breadCrumbs} /> : null}
        <h1 className="products-banner__heading">{title}</h1>
        <DangerouslySetInnerHtml
          className="products-banner__description"
          html={bannerDescription}
        />
        <CompatibilityList tags={tags} />
        <Link
          className="products-banner__link"
          to={bannerLink[0].queryString}
          aria-label={bannerLinkAriaLabel}
        >
          <i className="products-banner__link-arrow" />
          {bannerLink[0].name}
        </Link>
      </div>
      {bannerImage && bannerImageMobile ? (
        !isMobile ? (
          <UmbracoImage
            image={bannerImage}
            alt={bannerImage?.altText}
            className="products-banner__image-holder"
          />
        ) : (
          <UmbracoImage
            image={bannerImageMobile}
            alt={bannerImageMobile?.altText}
            className="products-banner__image-holder"
          />
        )
      ) : null}
    </div>
  );
};

export default ProductsBanner;
