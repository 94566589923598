import React, { FC } from 'react';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IProductsCompany } from './models';

import './ProductsCompany.scss';

const ProductsCompany: FC<IProductsCompany> = ({ title, productsList, scrollId }) => (
  <section className="products-company" id={scrollId}>
    <h2 className="products-company__heading">{title}</h2>

    {productsList?.length ? (
      <div className="products-company__list">
        {productsList.map((product) => (
          <div key={product.properties.text} className="products-company__item">
            <UmbracoImage
              image={product.properties.image}
              alt={product.properties.image.altText}
              className="products-company__image"
            />
            <DangerouslySetInnerHtml
              className="products-company__description"
              html={product.properties.description}
            />
          </div>
        ))}
      </div>
    ) : null}
  </section>
);

export default ProductsCompany;
