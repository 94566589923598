import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import Layout from 'components/Layout';
import ProductsBanner from 'components/ProductsBanner';
import Qualities from 'components/Qualities';
import ProductsCompany from 'components/ProductsCompany';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { TComponentProps } from './models';

import 'styles/main.scss';
import './ProductsLanding.scss';

const ProductsLanding: FC<TComponentProps> = ({
  pageContext: { breadCrumbs },
  data: {
    productsLandingPage,
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <Layout
      seo={{
        seoMetaTitle: productsLandingPage.seoMetaTitle,
        seoMetaKeywords: productsLandingPage.seoMetaKeywords,
        seoMetaDescription: productsLandingPage.seoMetaDescription,
        seoCanonicalUrl: productsLandingPage.seoCanonicalUrl,
        seoExternalHreflangs: productsLandingPage.seoExternalHreflangs,
        seoImage: productsLandingPage.seoImage,
        ogPageType: productsLandingPage.ogPageType,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={productsLandingPage.link}
    >
      {productsLandingPage?.productsBanner?.length ? (
        <ProductsBanner
          title={productsLandingPage.productLandingTitle}
          bannerDescription={productsLandingPage.productsBanner[0].properties.bannerDescription}
          bannerImage={productsLandingPage.productsBanner[0].properties.bannerImage}
          bannerImageMobile={productsLandingPage.productsBanner[0].properties.bannerImageMobile}
          bannerLink={productsLandingPage.productsBanner[0].properties.bannerLink}
          bannerLinkAriaLabel={productsLandingPage.productsBanner[0].properties.bannerLinkAriaLabel}
          breadCrumbs={breadCrumbs}
          tags={productsLandingPage.tags}
        />
      ) : null}
      {productsLandingPage.wideText ? (
        <DangerouslySetInnerHtml
          className="products-landing__wide-text"
          html={productsLandingPage.wideText}
        />
      ) : null}
      {productsLandingPage.qualities ? (
        <Qualities
          qualitiesSeoTitle={productsLandingPage.qualities[0].properties.qualitiesSeoTitle}
          qualitiesList={productsLandingPage.qualities[0].properties.qualitiesList}
        />
      ) : null}
      {productsLandingPage.wideImage ? (
        !isMobile ? (
          <UmbracoImage
            image={productsLandingPage.wideImage}
            alt={productsLandingPage.wideImage.altText}
            className="products-landing__wide-image"
          />
        ) : (
          <UmbracoImage
            image={productsLandingPage.wideImageMobile}
            alt={productsLandingPage.wideImageMobile.altText}
            className="products-landing__wide-image"
          />
        )
      ) : null}
      {productsLandingPage.products ? (
        <ProductsCompany
          title={productsLandingPage.products[0].properties.title}
          scrollId={productsLandingPage.products[0].properties.scrollId}
          productsList={productsLandingPage.products[0].properties.productsList}
        />
      ) : null}
      {productsLandingPage.commonText ? (
        <DangerouslySetInnerHtml
          className="products-landing__text"
          html={productsLandingPage.commonText}
        />
      ) : null}
    </Layout>
  );
};

export default ProductsLanding;

export const query = graphql`
  query ProductsLandingPageQuery($lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    productsLandingPage(lang: { eq: $lang }) {
      productLandingTitle
      seoMetaTitle
      seoMetaKeywords
      seoMetaDescription
      seoCanonicalUrl
      seoExternalHreflangs {
        key
        value
      }
      seoImage
      ogPageType
      link
      tags {
        id
        title
        name
        visible
      }
      productsBanner {
        properties {
          bannerDescription
          bannerLink {
            name
            udi
            url
            icon
            target
            queryString
          }
          bannerLinkAriaLabel
          bannerImage {
            altText
            fallbackUrl
            fluid {
              srcSet
              base64
            }
          }
          bannerImageMobile {
            altText
            fallbackUrl
            fluid {
              srcSet
              base64
            }
          }
        }
      }
      wideText
      wideImage {
        altText
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      wideImageMobile {
        altText
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      commonText
      qualities {
        properties {
          qualitiesSeoTitle
          qualitiesList {
            properties {
              icon {
                altText
                fallbackUrl
                fluid {
                  srcSet
                  base64
                }
              }
              text
            }
          }
        }
      }
      products {
        properties {
          title
          scrollId
          productsList {
            properties {
              image {
                altText
                fallbackUrl
                fluid {
                  srcSet
                  base64
                }
              }
              description
            }
          }
        }
      }
    }
  }
`;
