import React, { FC } from 'react';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IQualities } from './models';

import './Qualities.scss';

const Qualities: FC<IQualities> = ({ qualitiesSeoTitle, qualitiesList }) => (
  <section className="qualities">
    <h2 className="qualities__heading sr-only">{qualitiesSeoTitle}</h2>

    {qualitiesList?.map((qualitiesItem) => (
      <div key={qualitiesItem.properties.text} className="qualities__item">
        <UmbracoImage
          image={qualitiesItem.properties.icon}
          alt={qualitiesItem.properties.icon.altText}
          className="qualities__icon"
        />
        <DangerouslySetInnerHtml
          className="qualities__description"
          html={qualitiesItem.properties.text}
        />
      </div>
    ))}
  </section>
);

export default Qualities;
